@mixin button-solid($background-color: black, $text-color: black) {
  display: inline-block;
  cursor: pointer;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  outline: none;
  padding: 6px 8px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);

  @include mq(m) {
    padding: 6px 5px;
  }
  @include mq(xl) {
    padding: 12px 15px;
    line-height: 16px;
    font-size: 16px;
  }

  @if $background-color == black {
    border-color: $background-color;
    color: $white;
    background-color: $background-color;

    &:hover,
    &:focus,
    &:active {
      color: $background-color;
      background-color: $white;
      border-color: $background-color;
    }
  } @else if $background-color == white {
    border-color: $background-color;
    color: $text-color;
    background-color: $background-color;

    &:hover,
    &:focus,
    &:active {
      color: $background-color;
      background-color: $text-color;
      border-color: $text-color;
    }
  } @else {
    border-color: $background-color;
    color: $text-color;
    background-color: $background-color;

    &:hover,
    &:focus,
    &:active {
      color: $background-color;
      background-color: $text-color;
      border-color: $text-color;
    }
  }
}

@mixin right-arrow-button {
  background: none;
  border: solid black;
  border-width: 0 2px 2px 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  height: 16px;
  margin-bottom: auto;
  margin-top: auto;
  opacity: 0.3;
  outline: inherit;
  padding: 0;
  padding: 6px;
  transform: rotate(-45deg);
  width: 16px;

  &:visited,
  &:focus,
  &:hover,
  &:active {
    opacity: 1;
  }
}

@mixin left-arrow-button {
  @include right-arrow-button();
  transform: rotate(135deg);
}

@mixin down-arrow-button {
  @include right-arrow-button();
  height: 10px;
  padding: 5px;
  transform: rotate(45deg);
  width: 10px;

  &:focus {
    opacity: 0.3;
  }
}
