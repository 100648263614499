@import "../../styles/index.scss";

.footer {
  background: #000000;
  display: grid;
  justify-items: center;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);

  @include mq(xl) {
    grid-template-columns: auto 1440px auto;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;

    @include mq(m) {
      padding-left: 0;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    @include mq(xl) {
      grid-column: 2;
    }
  }

  &__links {
    display: grid;
    grid-auto-flow: column;

    @include mq(s) {
      padding-left: 64px;
      padding-right: 64px;
    }
    @include mq(m) {
      padding-left: 128px;
      padding-right: 128px;
    }

    &__link {
      text-decoration: none;
      color: $white;
      font-size: 18px;
      font-weight: bold;
      justify-self: center;

      &:hover {
        color: #ed361e;
      }
    }
  }

  &__patreon {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__description {
      padding-top: 20px;
      font-size: 18px;
      color: $white;
      width: 250px;
      text-align: center;

      @include mq(m) {
        width: auto;
        padding-top: 40px;
        font-size: 24px;
      }
    }

    &__button {
      @include button-solid(#ed361e, white);
      margin-top: 20px;
      width: 250px;
      font-weight: bold;
      font-size: 20px;
      padding: 12px;
      align-self: center;

      @include mq(xl) {
        width: 300px;
        font-size: 20px;
      }
    }
  }

  &__disclaimer {
    padding-top: 35px;
    width: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq(s) {
      width: 500px;
    }
    @include mq(m) {
      width: 600px;
      padding-top: 40px;
    }

    &__text {
      font-size: 18px;
      color: $white;
      padding-bottom: 15px;
    }
  }
}
