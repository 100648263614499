@import "../../styles/index.scss";

.card {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  margin-left: 32px;
  margin-right: 32px;
  background: $offwhite;

  &:hover {
    transform: scale(1.05);
  }

  &__image-container {
    display: grid;
    position: relative;

    &__image {
      display: block;
      margin-bottom: 10px;
      width: 100%;
      transition: 0.5s ease-in;
      grid-row: 1;
    }
  }

  &__content {
    display: grid;
    padding-left: 16px;
    padding-right: 16px;

    &__title {
      font-weight: bold;
      font-size: 28px;
      padding-bottom: 10px;
      color: $redwood;

      @include mq(m) {
        font-size: 32px;
      }
    }
    &__text,
    &__text-list {
      font-size: 18px;
      padding-bottom: 5px;

      @include mq(m) {
        font-size: 22px;
      }
    }
    &__button {
      @include button-solid($lavared, $white);
      margin-top: 25px;
      width: 100%;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 25px;
      padding: 8px;

      @include mq(m) {
        font-size: 24px;
        padding: 16px;
        width: calc(100% - 64px);
        justify-self: center;
      }
    }
  }
}
