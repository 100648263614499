@import "../../styles/index.scss";

.image-content-section {
  display: grid;
  justify-items: center;
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: 2px solid $redwood;

  @include mq(s) {
    grid-template-columns: 1fr 1fr;
    margin-left: 32px;
    margin-right: 32px;
  }
  @include mq(m) {
    margin-left: 128px;
    margin-right: 128px;
    grid-template-columns: 1fr 2fr;
    max-width: 1212px;
  }
  @include mq(xl) {
    grid-template-columns: 1fr 3fr;
  }

  &:last-child {
    border-bottom: none;
  }

  &[data-type="even"] {
    @include mq(m) {
      grid-template-columns: 2fr 1fr;
    }
    @include mq(xl) {
      grid-template-columns: 3fr 1fr;
    }

    .image-content-section {
      @include mq(s) {
        &__image-container {
          grid-column: 2;
          grid-row: 1;
        }
        &__content-container {
          grid-column: 1;
          grid-row: 1;
        }
      }
    }
  }

  &__image-container {
    width: 200px;

    @include mq(s) {
      width: 250px;
    }

    &__image {
      width: 100%;
      mix-blend-mode: multiply;
    }
  }

  &__content-container {
    padding-top: 10px;
    justify-self: start;

    &__title {
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 10px;
      color: $redwood;

      @include mq(m) {
        font-size: 32px;
        padding-bottom: 15px;
      }
    }
    &__subclass {
      margin-bottom: 10px;

      // text-decoration: underline;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: $black;
        text-decoration: none;
        font-size: 18px;

        @include mq(m) {
          font-size: 22px;
        }

        span {
          font-weight: bold;
          border-bottom: 1px solid $lavared;
          color: $lavared;
        }
        &:hover {
          color: $redwood;

          span {
            border-bottom: 1px solid $redwood;
            color: $redwood;
          }
        }
      }
    }
  }
}
