@import "../../styles/index.scss";

.intro-text {
  text-align: center;
  padding-bottom: 25px;
  padding-left: 16px;
  padding-right: 16px;

  @include mq(m) {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 50px;
  }

  &__title {
    font-weight: bold;
    font-size: 28px;
    padding-bottom: 10px;
    color: $redwood;

    @include mq(m) {
      font-size: 40px;
    }
  }
  &__subtitle {
    font-size: 22px;
    padding-bottom: 5px;

    @include mq(m) {
      font-size: 28px;
    }
  }
}
