@import "../../styles/index.scss";

.email-page {
  @include page-layout;

  &__content {
    @include page-content;
    display: grid;
    justify-items: center;
    padding-top: 50px;

    @include mq(m) {
      padding-top: 100px;
    }
  }

  &__form {
    display: grid;
    width: 100%;

    @include mq(s) {
      width: 600px;
    }

    &__title {
      font-weight: bold;
      font-size: 32px;
      padding-bottom: 10px;
      color: $redwood;
      padding-left: 16px;
      padding-right: 16px;

      @include mq(m) {
        font-size: 48px;
      }
    }

    &__description {
      font-size: 22px;
      padding-bottom: 25px;
      padding-left: 16px;
      padding-right: 16px;

      @include mq(m) {
        font-size: 28px;
        max-width: 600px;
        padding-bottom: 35px;
      }
    }

    &__input-heading {
      font-size: 18px;
      padding-bottom: 5px;
      padding-left: 16px;
      padding-right: 16px;

      @include mq(m) {
        font-size: 24px;
        padding-bottom: 10px;
      }
    }

    &__input-container {
      padding-bottom: 15px;
      padding-left: 16px;
      padding-right: 16px;

      @include mq(m) {
        padding-bottom: 30px;
      }
    }

    &__input {
      padding: 10px;
      font-size: 14px;
      width: calc(100% - 32px);
      margin-bottom: 5px;

      @include mq(m) {
        width: 100%;
        font-size: 18px;
      }

      &[data-type="message"] {
        height: 120px;

        @include mq(m) {
          height: 150px;
        }
      }
    }

    &__error {
      color: $lavared;
      font-size: 12px;

      @include mq(m) {
        font-size: 14px;
      }
    }

    &__button {
      @include button-solid($redwood, $white);
      margin-top: 15px;
      width: calc(100% - 32px);
      margin-left: 16px;
      margin-right: 16px;
      font-weight: bold;
      font-size: 16px;
      padding: 12px;
      font-size: 20px;

      @include mq(s) {
        max-width: 200px;
      }
      @include mq(m) {
        margin-top: 20px;
        padding: 12px 8px;
      }
    }
  }

  &__modal {
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: hidden;
    background-color: rgba(8, 7, 7, 0.6);
  }
}

.modal-wrapper {
  background: $white;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 200px;

  @include mq(s) {
    width: 300px;
  }
  @include mq(m) {
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 48px;
    width: 350px;
  }

  &__close-button-grid {
    position: sticky;
    top: -1px;
    left: 0;
    display: grid;
    justify-items: end;
    background-color: $white;
    z-index: 210;
    height: 57px;

    @include mq(m) {
      height: 73px;
    }

    &__close-container {
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin-top: 8px;
      margin-right: -18px;

      @include mq(m) {
        width: 46px;
        height: 46px;
        margin-top: 18px;
        margin-right: -48px;
      }

      &__close-icon {
        height: 2px;
        width: 15px;
        background: $black;
        position: absolute;
        right: auto;
        margin-left: 7px;
        margin-top: 13px;

        @include mq(m) {
          margin-top: 28px;
          margin-left: 14px;
          margin-top: 18px;
        }

        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__content {
    font-size: 22px;
    text-align: center;

    @include mq(m) {
      font-size: 28px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding-top: 22px;

    @include mq(s) {
      padding-top: 28px;
    }
    @include mq(m) {
      padding-top: 36px;
    }

    &__cancel-button {
      @include button-solid($redwood, $white);
      width: 100%;
      font-weight: bold;
      font-size: 16px;
      padding: 12px;
      font-size: 20px;

      @include mq(s) {
        width: 200px;
      }
      @include mq(m) {
        padding: 12px 8px;
      }
    }
  }
}
