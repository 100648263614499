@import "../../styles/index.scss";

.three-panels {
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
  justify-items: center;

  @include mq(m) {
    display: flex;
    justify-content: space-around;
    padding-left: 32px;
    padding-right: 32px;
  }
  @include mq(xl) {
    padding-left: 64px;
    padding-right: 64px;
  }

  &__section {
    padding: 20px;
    border: 1px solid $redwood;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 300px;
    border-radius: 5px;
    background: #964437;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    min-height: 170px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq(s) {
      max-width: 350px;
    }
    @include mq(m) {
      margin-bottom: 0;
      max-width: 250px;
    }
    @include mq(xl) {
      max-width: 300px;
      padding: 30px;
    }

    &__title {
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 10px;
      color: $white;
    }
    &__section,
    &__link {
      font-size: 20px;
      margin-bottom: 10px;
      margin-left: 5px;
      font-weight: bold;
      color: $white;
    }
    &__link {
      color: $parchment;

      &:hover {
        color: $lavared;
      }
    }
    &__button {
      @include button-solid($white, $lavared);
      margin-top: 30px;
      width: 100%;
      font-weight: bold;
      padding: 12px;
      font-size: 20px;

      @include mq(m) {
      }
      @include mq(xl) {
        font-size: 20px;
      }
    }
  }
}
